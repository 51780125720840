import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
import NumberLabel from '../../../components/worksafe-vic/NumberLabel';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1 {...{
      "id": "optional-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#optional-settings",
        "aria-label": "optional settings permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Optional settings`}</h1>
    <h3 {...{
      "id": "set-a-view-type",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-a-view-type",
        "aria-label": "set a view type permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set a view type`}</h3>
    <p>{`The provider submission and response user interface can be rendered as a `}<strong parentName="p">{`lightbox modal`}</strong>{` or an `}<strong parentName="p">{`embedded frame`}</strong>{`.`}</p>
    <h5 {...{
      "id": "lightbox-modal-default",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#lightbox-modal-default",
        "aria-label": "lightbox modal default permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lightbox modal (default)`}</h5>
    <p>{`By default, the SDK's self-contained UI is encapsulated in a lightbox modal, styled by the SDK:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/wsvlightbox-modal.png",
        "alt": null
      }}></img></p>
    <h5 {...{
      "id": "embedded-frame",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#embedded-frame",
        "aria-label": "embedded frame permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Embedded frame`}</h5>
    <p>{`Alternatively if you want to have more control over how the container of the self-contained UI is styled you can embed the UI in your own container, identified by a HTML `}<inlineCode parentName="p">{`id`}</inlineCode>{` attribute:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/wsv-embedded-frame.png",
        "alt": null
      }}></img></p>
    <h6 {...{
      "id": "see-the-playground-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h6" {...{
        "href": "#see-the-playground-example",
        "aria-label": "see the playground example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h6" {...{
        "href": "https://playground.medipass.io/?query=%7B%22containerId%22:%22medipass-container%22%7D&config=%7B%22env%22:%22stg%22,%22apiKey%22:%225c8b11385d73d6005590d040:-p9FNJtTB_9fWR7BMxu-vJZVLiQYZ-wyetLzrHzyOxA%22,%22appId%22:%22web-application%22%7D"
      }}>{`See the playground example`}</a></h6>
    <h5 {...{
      "id": "setting-up-the-embedded-frame",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#setting-up-the-embedded-frame",
        "aria-label": "setting up the embedded frame permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up the embedded frame:`}</h5>
    <p><strong parentName="p">{`In your HTML:`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div id="medipass-container"></div>
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note: You can style the container HTML element however you like.`}</p>
    </blockquote>
    <p><strong parentName="p">{`In your JS:`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`sdk.renderCreateTransaction({
  // your payload
}, { containerId: 'medipass-container' })
`}</code></pre>
    <p>{`With these two snippets implemented, you should be able to get an embedded Medipass frame working.`}</p>
    <h3 {...{
      "id": "disable-modification-of-service-items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#disable-modification-of-service-items",
        "aria-label": "disable modification of service items permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Disable modification of service items`}</h3>
    <p>{`To prevent providers from changing item details in the Tyro Health  interface on error, set `}<Highlight mdxType="Highlight">{`disableModifyServiceItems`}</Highlight>{` to true in the SDK options.`}</p>
    <p>{`This option is used by some practice management systems to maintain referential integrity on problematic transactions.`}</p>
    <pre><code parentName="pre" {...{}}>{`sdk.renderCreateTransaction({
  // your payload
}, {
  disableModifyServiceItems: true
})
`}</code></pre>
    <h3 {...{
      "id": "branding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#branding",
        "aria-label": "branding permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Branding`}</h3>
    <p>{`Your integration must include appropriate branding attribution for features supported by Tyro Health. Where branding attribution is not embedded in the Tyro Health service, such as with the Tyro Health  SDK, we will work with you on agreeing attribution that is appropriate.`}</p>
    <h3 {...{
      "id": "integrated-help---live-chat",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#integrated-help---live-chat",
        "aria-label": "integrated help   live chat permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h3" {...{
        "href": "https://docs.medipass.io/sdk/transaction/support/"
      }}>{`Integrated help - live chat`}</a></h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      